import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://player.vimeo.com/video/387481314">
    <SEO title="Gender Identity - Radical Lectures" />
  </Layout>
)

export default SermonPost
